<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">{{ userId ? 'Manage' : 'Add' }} User</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<router-link to="/users/list" class="btn btn-light mb-4 btn-sm">
				Go back
			</router-link>
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="row" v-else>
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">
									{{ `${userId ? 'Edit' : 'Add a New'} User` }}
								</h4>
							</div>
						</div>
						<div class="card-body">
							<div
								class="alert alert-danger alert-dismissible fade show"
								role="alert"
								v-if="errorMessage"
								>
								{{ errorMessage }}
							</div>
							<form @submit.prevent="manageUser">
								<div class="row mt-4">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> First name </label>
											<input
												v-model.trim="user.fname"
												required
												type="text"
												class="form-control"
												/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Last name </label>
											<input
												v-model.trim="user.lname"
												required
												type="text"
												class="form-control"
												/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> Email address </label>
											<input
												v-model.trim="user.email"
												required
												type="email"
												class="form-control"
												/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Phone </label>
											<input
												v-model.trim="user.phone"
												required
												type="text"
												class="form-control mb-3"
												placeholder="(___)___-____"
												inputmode="text"
												/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6" v-if="!userId">
										<div class="form-group">
											<label class="mb-1"> Password </label>
											<input
												v-model.trim="user.password"
												:required="!userId"
												type="password"
												class="form-control"
												/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<label class="form-label">Company (optional)</label>
										<v-select
											v-model="user.corporate_id"
											class="form-group"
											:options="companies"
											label="corporate_name"
											:reduce="(company) => company.id"
											>
										</v-select>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label">Date of Birth</label>
											<div style="width: 100%">
												<v-datepicker
													v-model="user.dob"
													value-type="YYYY-MM-DD"
													format="DD MMMM, YYYY"
													style="width: 100%"
													></v-datepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<div class="form-group d-inline-flex">
											<div class="form-radio ml-2">
												<input
													v-model="user.gender"
													class="form-radio-input"
													type="radio"
													id="male"
													value="male"
													required
													/>
												<label for="male" class="form-radio-label ml-2"
													>Male</label
													>
											</div>
											<div class="form-radio ml-4">
												<input
													v-model="user.gender"
													class="form-radio-input"
													type="radio"
													id="female"
													value="female"
													required
													/>
												<label for="female" class="form-radio-label ml-2"
													>Female</label
													>
											</div>
										</div>
									</div>
								</div>
								<button
									type="submit"
									class="btn btn-primary mt-4"
									:disabled="processing"
									>
									{{
										processing
											? 'Processing...'
											: `${userId ? 'Update' : 'Add'} User`
									}}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: ['userId'],
  data () {
    return {
      user: {
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: '',
        corporate_id: '',
        dob: '',
        gender: 'male'
      },
      companies: [],
      processing: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false
    }
  },
  created () {
    this.fetchCompanies()

    if (this.userId) {
      this.fetchUser()
    }
  },
  methods: {
    fetchCompanies () {
      this.axios.get('/v1/corporates?limit=100000').then((res) => {
        this.companies = res.data.data
      })
    },
    fetchUser () {
      this.loading = true
      this.errorLoading = false

      this.axios
        .get(`/v1/users/${this.userId}`)
        .then((res) => {
          this.user = { ...res.data }
        })
        .catch(() => {
          this.errorLoading = true
        })
        .finally(() => (this.loading = false))
    },
    manageUser () {
      this.processing = true
      this.errorProcessing = false

      this.errorMessage = ''

      this.axios({
        method: this.userId ? 'PATCH' : 'POST',
        url: this.userId ? `/v1/users/${this.userId}` : '/v1/users/',
        data: {
          fname: this.user.fname,
          lname: this.user.lname,
          phone: this.user.phone,
          email: this.user.email,
          password: this.user.password,
          gender: this.user.gender,
          dob: this.user.dob,
          corporate_id: this.user.corporate_id || null // Since the company field is optional, we set 'corporate_id' to null if no company was selected
        }
      })
        .then((res) => {
          this.$swal({
            icon: 'success',
            title: `User ${this.userId ? 'Updated' : 'Added'}`,
            text: `The user "${this.user.fname}" has been ${
              this.userId ? 'updated' : 'added'
            } succesfully`,
            showCloseButton: true
          }).then(() => {
            this.$router.push({
              name: 'ShowUser',
              params: { userId: res.data.id }
            })
          })
        })
        .catch((e) => {
          this.errorProcessing = true

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg

          this.$swal({
            icon: 'error',
            text: 'An error occured, please try again',
            showCloseButton: true
          })
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
